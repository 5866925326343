import axios from 'axios'
import {API_URL} from "../../../helpers/crud-helper/consts";
const FAQ_URL = `${API_URL}/faqs`


const getFaqsPage = (): Promise<any> => {
    return axios
        .get(`${FAQ_URL}`)
        .then((response) => response)
}

export {getFaqsPage}

import React, {Suspense, useEffect, useRef, useState} from "react";
import { SocialMeta } from "../../components/SocialMeta";
import { getHomeData } from "../../core/home/requests";
import TopBanner from "./components/TopBanner";
import Resale from "./components/Resale";
import Offplan from "./components/Offplan";
import Roadmap from "./components/Roadmap";
import Blog from "./components/Blog";
import Team from "./components/Team";
import About from "./components/About";
import { useHeaderContext } from "../../layout/context/HeaderContext";
import Story from "./components/Story";
import Success from "./components/Success";
import Contact from "./components/Contact";
import {useLocation} from "react-router-dom";
import {scrollToSection} from "../../helpers/functions";

interface MetaTags {
    meta_title?: string;
    meta_description?: string;
}

interface HomeData {
    cities: any[];
    projects: any[];
    subjects: any[];
    projectFirst: any;
    roadmap: any[];
    testimonials: any[];
    blog: {
        data: any[];
        last_page: number;
        current_page: number;
        total: number;
    };
    team: any[];
    paginationData: {
        last_page: number;
        current_page: number;
        total: number;
    };
    paginationBlogData: {
        last_page: number;
        current_page: number;
        total: number;
    };
    cityFirst: any;
    metaTags: MetaTags;
}

const Home = () => {
    const [homeData, setHomeData] = useState<HomeData>({
        cities: [],
        projects: [],
        subjects: [],
        projectFirst: null,
        roadmap: [],
        testimonials: [],
        blog: { data: [], last_page: 0, current_page: 0, total: 0 },
        team: [],
        paginationData: { last_page: 0, current_page: 0, total: 0 },
        paginationBlogData: { last_page: 0, current_page: 0, total: 0 },
        cityFirst: null,
        metaTags: {},
    });

    const { setVariant, setLogoColor } = useHeaderContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getHomeData();
                const {
                    cities,
                    projects,
                    subjects,
                    project_mapping_first,
                    roadmap,
                    testimonials,
                    blog,
                    team,
                    city_first,
                    page,
                } = response.data;

                setHomeData({
                    cities,
                    projects,
                    subjects,
                    projectFirst: project_mapping_first,
                    roadmap: roadmap.data,
                    testimonials,
                    blog,
                    team,
                    cityFirst: city_first,
                    metaTags: page,
                    paginationData: city_first.resale_leasing.meta,
                    paginationBlogData: blog,
                });
            } catch (error) {
                console.error("Error fetching home data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setVariant("default");
        setLogoColor("light-dark");
        return () => {
            setVariant("default");
            setLogoColor("light-dark");
        };
    }, [setVariant, setLogoColor]);

    const {
        cities,
        cityFirst,
        projects,
        projectFirst,
        roadmap,
        testimonials,
        blog,
        team,
        subjects,
        metaTags,
        paginationData,
        paginationBlogData,
    } = homeData;

    const location = useLocation();
    const roadmapRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (location.state?.section === 'roadmap') {
           setTimeout(() => {
               const section = document.getElementById('roadmap');
               if (location.pathname === "/") {
                   if (section) {
                       window.scrollTo({
                           top: section.offsetTop - 20, // Adjust offset as needed
                           behavior: "smooth",
                       });
                   }
               }
           }, 1000)
        }
    }, [location]);

    // Scroll to Offplan section if the URL contains ?section=offplan
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const section = params.get("section");

        if (section === "offplan") {
            const offplanSection = document.getElementById("offplan");
            if (offplanSection) {
                window.scrollTo({
                    top: offplanSection.offsetTop - 20, // Adjust the offset if needed
                    behavior: "smooth",
                });
            }
        }
    }, [location, homeData.projects, homeData.projectFirst]);


    return (
        <>
            <SocialMeta
                title={metaTags.meta_title || "Olive Branch Properties: Real Estate Specialists in Dubai"}
                description={
                    metaTags.meta_description ||
                    "Find your dream property in Dubai! Explore luxury apartments, villas, & off-plan projects. Expert agents. Contact Olive Branch Properties today!"
                }
            />

            <TopBanner
                title={'<div class="text-white text-center"><p class="fs-16 bolder fw-700">WELCOME TO</p> <h1 class="fs-65 mt-4 mb-lg-4">Olive Branch Properties</h1></div>'}
                showBtn={true}
                video={"/assets/olive-new-video.mp4"}
            />

            <About />

            <section id="resale" className="resale position-relative mt-5">
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-lg-12 text-center mt-5 mb-4">
                            <h3 className="title-header mt-2 pb-2">Resale & Leasing</h3>
                        </div>
                    </div>
                    {cities && cityFirst && (
                        <Resale cities={cities} cityFirst={cityFirst} pagination={paginationData} />
                    )}
                </div>
            </section>

            <Suspense fallback={<div></div>}>
                {projects && projectFirst &&
                    <div id="offplan">
                        <Offplan projects={projects} projectFirst={projectFirst}/>
                    </div>
                }
                {roadmap && <Roadmap roadmap={roadmap}/>}
                <Story/>
                {blog.data.length > 0 && (
                    <Blog blog={blog} pagination={paginationBlogData} />
                )}
                {team.length > 0 && <Team team={team} />}
                {testimonials.length > 0 && <Success testimonials={testimonials} />}
                <Contact subjects={subjects} />
            </Suspense>
        </>
    );
};

export default React.memo(Home);

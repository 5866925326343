import React, {FC, useCallback, useEffect, useState} from "react";
import Select from "react-select";
import clsx from "clsx";
import ResaleCard from "app/components/Card/ResaleCard";
import {useNavigate, useParams} from "react-router-dom";
import TopBanner from "../home/components/TopBanner";
import {useHeaderContext} from "../../layout/context/HeaderContext";
import {indexProperties} from "./core/requests";
import {showCity} from "../../core/home/requests";
import {SocialMeta} from "../../components/SocialMeta";

type City = {
    name: string;
    slug: string;
};

type PaginationData = {
    last_page: number;
    current_page: number;
    total: number;
};

type PropertyShowProps = {
    cities?: City[];
    cityFirst?: any;
    selectedPage?: any;
    pagination?: any;
};

const PropertyShow: FC<PropertyShowProps> = () => {
    const {slug} = useParams<{ slug: string }>();
    const [cities, setCities] = useState<City[]>([]);
    const [activeCity, setActiveCity] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingMoreProperties, setLoadingMoreProperties] = useState<boolean>(false);
    const [paginationData, setPaginationData] = useState<PaginationData>({
        last_page: 0,
        current_page: 0,
        total: 0,
    });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [defaultCity, setDefaultCity] = useState<{ label: string; value: string } | null>(null);

    const {setVariant, setLogoColor} = useHeaderContext();
    const navigate = useNavigate();

    const handleTabChange = (value: string) => {
        setLoading(true);
        navigate(`/properties/${value}`);
    };

    const loadMoreProperties = useCallback(async () => {
        if (!activeCity) return;

        const newPage = currentPage + 1;
        setCurrentPage(newPage);
        setLoadingMoreProperties(true);

        try {
            const response = await showCity(activeCity.slug, newPage, 9);
            const {resale_leasing} = response.data.city;

            setPaginationData(resale_leasing.meta);

            setActiveCity((prevState: any) => ({
                ...prevState,
                resale_leasing: {
                    ...prevState.resale_leasing,
                    data: [
                        ...(prevState.resale_leasing?.data || []),
                        ...resale_leasing.data,
                    ],
                    meta: resale_leasing.meta,
                },
            }));
        } catch (error) {
            console.error("Error loading more properties:", error);
        } finally {
            setLoadingMoreProperties(false);
        }
    }, [activeCity, currentPage]);

    useEffect(() => {
        setVariant("property");
        setLogoColor("dark");
        return () => {
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await indexProperties(1, 9);
                setCities(response.data.cities);
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        };

        const fetchCityData = async () => {
            if (!slug) return;

            try {
                const response = await showCity(slug, 1, 9);
                const {city} = response.data;

                setPaginationData(city.resale_leasing.meta);
                setActiveCity(city);
                setLoading(false);
                setDefaultCity({label: city.name, value: city.slug});
            } catch (error) {
                console.error("Error fetching city data:", error);
            }
        };

        fetchCities().then(r => {
        });
        fetchCityData().then(r => {
        });
    }, [slug]);

    console.log(activeCity)

    return (
        <>
            <SocialMeta
                title={`${activeCity && activeCity.meta_title ? activeCity.meta_title : activeCity ? activeCity.name + ' Properties' : 'Olive Branch Properties'}`}
                description={`${activeCity && activeCity.meta_title ? activeCity.meta_description : "Find your dream property in Dubai! Explore luxury apartments, villas, & off-plan projects. Expert agents. Contact Olive Branch Properties today!" }`}
            />

            <section className="resale bg-property-page position-relative">
                {activeCity && <TopBanner title={`<h1 class="title-2 fs-65">${activeCity.name} Properties</h1>`}/>}
                <div className="container pb-5 pt-5">
                    <div className="bg-white bdr-t-10 ps-3 pe-3">
                        <div className="row justify-content-center pb-4 align-items-center pt-4">
                            <div className="col-lg-3">
                                <div className="title-main">Search for your property:</div>
                            </div>
                            <div className="col-lg-6 mt-2">
                                <Select
                                    className={clsx("text-dark w-100")}
                                    closeMenuOnSelect
                                    placeholder="Select The Location"
                                    options={cities.map(({name, slug}) => ({label: name, value: slug}))}
                                    isSearchable
                                    onChange={(newValue) => newValue && handleTabChange(newValue.value)}
                                    value={defaultCity}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="bg-secondary-light bdr-b-10 position-relative">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 text-center pt-4">
                                {activeCity && (
                                    <>
                                        <h4 className="title-main">{activeCity.name}</h4>
                                        <p className="main-description ps-2 pe-2">{activeCity.description}</p>
                                    </>
                                )}
                            </div>
                            <div className="col-lg-11 mt-1">
                                <hr className="separator"/>
                            </div>
                            <div className="row justify-content-center mt-5 ps-lg-4 pe-lg-4">
                                {loading ? (
                                    <div className="col-md-11 text-center mb-5">
                  <span className="indicator-progress" style={{display: "block"}}>
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                                    </div>
                                ) : (
                                    activeCity?.resale_leasing?.data?.length > 0 &&
                                    activeCity.resale_leasing.data.map((item: any) => (
                                        <div className="col-lg-4 col-md-12 mb-3" key={item.id}>
                                            <ResaleCard item={item}/>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        {activeCity && activeCity.resale_leasing?.data?.length < paginationData.total && (
                            <div className="row justify-content-center pb-5">
                                <div className="col-lg-3">
                                    <button
                                        onClick={loadMoreProperties}
                                        className="btn btn-primary lg w-100"
                                        disabled={loadingMoreProperties}
                                    >
                                        {loadingMoreProperties ? (
                                            <span className="indicator-progress" style={{display: "block"}}>
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                                        ) : (
                                            "Load more"
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default PropertyShow;

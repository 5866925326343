import axios from 'axios'
import {API_URL} from "../../../helpers/crud-helper/consts";
const ABOUT_URL = `${API_URL}/about`


const getAboutPage = (): Promise<any> => {
    return axios
        .get(`${ABOUT_URL}`)
        .then((response) => response)
}

export {getAboutPage}

import React, {useEffect, useRef, useState} from "react";
import ListYourPropertyForm from "./components/ListYourPropertyForm";
import {useHeaderContext} from "../../layout/context/HeaderContext";
import {SocialMeta} from "../../components/SocialMeta";
import {getListYourPropertyPage} from "./core/requests";


const ListYourProperty = () => {
    const contactFormRef = useRef<HTMLDivElement>(null);
    const {setVariant, setLogoColor} = useHeaderContext();
    const [metaTags, setMetaTags] = useState<any>();

    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("default");
        setLogoColor("white")
        return () => {
            // Clean up or reset when component unmounts
            setVariant("default");
            setLogoColor("white");
        };
    }, [setVariant, setLogoColor]);
    const scrollToContactForm = () => {
        if (contactFormRef.current) {
            contactFormRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };
    useEffect(() => {
        getListYourPropertyPage().then((response) => {
            setMetaTags(response.data.page)
        });
    }, []);
    return (
        <>
            <SocialMeta
                title={metaTags && metaTags.meta_title ? metaTags.meta_title : 'List Your Property'}
                description={metaTags && metaTags.meta_description ? metaTags.meta_description : 'We\'re here to make selling your property a smooth and rewarding experience.'}/>



            <section>
                <div className={'banner-block position-relative'}>
                    <img src={'/assets/images/list-property/banner-list-your-property.jpg'} className={'banner w-100'}
                         alt={'List your property with us | Olive Branch'}/>
                    <div className={'block-center text-white text-center mt-lg-5'}>
                        <h1 className={'fs-65'}>List Your Property with <br/> Olive Branch</h1>
                        <p>We're here to make selling your property a smooth and rewarding experience.</p>
                        <div className={'mt-4'}>
                            <button onClick={scrollToContactForm} className={'btn btn-primary lg'}> Get Started</button>
                        </div>
                    </div>
                </div>
                <div className={'position-relative'}>
                    <div className={'container'}>
                        <div className={'row mt-5'}>
                            <div className={'col-md-12 text-center mb-5'}>
                                <h3 className={'text-main'}>Discover the benefits of listing with us</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center  mb-5">
                            <div className="col-lg-2 col-md-6 col-12">
                                <div>
                                    <div className="text-center"><img
                                        src="/assets/images/list-property/guide.png"
                                        className="w-25 benefit-img object-cover" alt="Expert Guidance"/></div>
                                    <div className="mt-4 text-center"><h6 className="text-main">Expert Guidance</h6>
                                        <p className=" text-center pb-4 light-grey fs-12">
                                            Our experienced team will guide you through every step.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12">
                                <div>
                                    <div className="text-center"><img
                                        src="/assets/images/list-property/exposure.png"
                                        className="w-25 benefit-img object-cover" alt="Broad Exposure"/></div>
                                    <div className="mt-4 text-center"><h6 className="text-main">Broad Exposure</h6>
                                        <p className=" text-center pb-4 light-grey fs-12">
                                            Reach a large audience of potential buyers or renters.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12">
                                <div>
                                    <div className="text-center">
                                        <img
                                            src="/assets/images/list-property/transactions.png"
                                            className="w-25 benefit-img object-cover" alt="Efficient Transactions"/>
                                    </div>
                                    <div className="mt-4 text-center"><h6 className="text-main">Efficient
                                                                                                Transactions</h6>
                                        <p className={' text-center  pb-4 light-grey fs-12'}>
                                            Enjoy hassle-free processes with our streamlined services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12">
                                <div>
                                    <div className="text-center">
                                        <img
                                            src="/assets/images/list-property/insights.png"
                                            className="w-25 benefit-img object-cover" alt="Market Insights"/>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <h6 className="text-main">Market Insights</h6>
                                        <p className={'text-center pb-4 light-grey fs-12'}>
                                            Get the best value with our up-to-date market analysis.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'aside-placeholder d-lg-block d-md-none d-none'}>
                            <img src={'/assets/images/list-property/aside-placeholder.png'} alt="Olive Branch"/>
                        </div>
                    </div>
                </div>
                <div className={'bg-form mb-5'} ref={contactFormRef}>
                    <div className={'container'}>
                        <ListYourPropertyForm/>
                    </div>

                    <div className={'aside-placeholder-left d-lg-block d-md-none d-none'}>
                        <img src={'/assets/images/list-property/frame-big.png'} alt="Olive Branch"/>
                    </div>
                </div>

                <div className={'mb-5'}>
                    <div className={'container'}>
                        <div className={'row justify-content-center align-items-center'}>
                            <div className={'col-lg-5 mt-2'}>
                                <div>
                                    <img src={'/assets/images/list-property/building.png'} className={'w-100'}
                                         alt={'Olive Branch'}/>
                                </div>
                            </div>
                            <div className={'ps-lg-4 col-lg-4 mt-lg-1 mt-5'}>
                                <div>
                                    <h3 className={'text-main'}>Why List with Olive Branch?</h3>
                                </div>
                                <div className={'mt-4'}>
                                    <div className={'d-flex  mt-2'}>
                                        <div>
                                            <img src={'/assets/images/list-property/marketing.png'} className={'me-3'}
                                                 width={'40'} alt={'Olive Branch'}/>
                                        </div>
                                        <div className={'pe-lg-5 '}>
                                            <h6 className={'form-label-secondary'}>Comprehensive Marketing</h6>
                                            <p className={'pb-4 light-grey fs-12 pe-lg-5'}>
                                                We use innovative marketing strategies to highlight your property's best
                                                features.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'d-flex  mt-3'}>
                                        <div>
                                            <img src={'/assets/images/list-property/support.png'} className={'me-3'}
                                                 width={'40'} alt={'Olive Branch'}/>
                                        </div>
                                        <div className={'pe-lg-5 '}>
                                            <h6 className={'form-label-secondary'}>Professional Support</h6>
                                            <p className={'pb-4 light-grey fs-12 pe-lg-5'}>
                                                Our team is dedicated to ensuring a smooth transaction process.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'d-flex mt-3'}>
                                        <div>
                                            <img src={'/assets/images/list-property/services.png'} className={'me-3'}
                                                 width={'40'} alt={'Olive Branch'}/>
                                        </div>
                                        <div className={'pe-lg-5 '}>
                                            <h6 className={'form-label-secondary'}>Personalized Services</h6>
                                            <p className={'pb-4 light-grey fs-12 pe-lg-5'}>
                                                We listen to your needs and customize our approach for maximum .
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'bg-s position-relative'}>
                    <div className={'container'}>
                        <div className={'row mt-5'}>
                            <div className={'col-md-12 text-center pt-5 mb-5'}>
                                <h3 className={'text-main-lighter'}>Ready to take the next step?</h3>
                                <p className={'p-lighter'}>Connect with us today and let Olive Branch help you achieve
                                                           your property goals!</p>

                                <div className={'mt-4'}>
                                    <button className={'btn btn-primary lg'} onClick={scrollToContactForm}> Contact Us
                                                                                                            now
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={'aside-placeholder d-lg-block d-md-none d-none'}>
                        <img src={'/assets/images/list-property/frame-right.png'} alt="Olive Branch"/>
                    </div>
                    <div className={'aside-placeholder-left d-lg-block d-md-none d-none'}>
                        <img src={'/assets/images/list-property/frame-left.png'} alt="Olive Branch"/>
                    </div>
                </div>

            </section>


        </>
    );
};

export default ListYourProperty;
